<template>
  <div id="register">
    <MyheadCompnent></MyheadCompnent>
    <ChangePasswordCompnent></ChangePasswordCompnent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import ChangePasswordCompnent from '@/components/Browse/ChangePasswordCompnent.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  export default {
    name: 'Register',
    data() {
      return {

      }
    },
    components: {
      ChangePasswordCompnent,
      MyheadCompnent,
      MyfooterCompnent,
    },
  }
</script>

<style scoped lang="scss">
</style>